import React, { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { format } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, IconButton, InputBase, Paper, Stack, TextField, Typography } from "@mui/material";
import { setToken } from "../services/token";
import { getUrl } from "../auth/getUrl";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import "./style.css"

const Styles = styled.div`
  

  table {
    border-spacing: 0;
    border: 1px solid lightgrey;
    width: 100%;
  

  
    
  }
`;

function Table({ columns, data, update }: { columns: any, data: any, update: any }) {
  // Use the state and functions returned from useTable to build your UI
  const [sortBy, setsortBy] = useState([])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  React.useEffect(() => {
    console.log("sort");
  }, [sortBy]);

  const navigate = useNavigate();

  var onRowClick = (test:any) =>{
   
    var r = test.row.cells[0].value

    getUrl(`/api/payment_info_kassa?id=`+r)
    .then(res => {
      /*  props.refresh() */


      if (res.status == 401) {
         // props.setToken(false)
         navigate("/login")
         window.location.reload()

      }
      res.json()
     /*  setIsRefreshing(false)
      navigate("/lk/" + user_id) */
   
  }, error => {
      navigate("/login")
  }).then(res=>{
    debugger
  })

  }
  // Render the UI for your table
  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={update}
      hasMore={true}
      loader={<h4>Loading...</h4>}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                //  debugger

                  if ((cell as any).column.id =="YouKassa"){
                    return (
                      <td onClick={()=>{onRowClick((cell as any))}}{...cell.getCellProps()}>open</td>
                    );
                  }
                  
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}


function TransactionTable(props: any) {
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_TITLE
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: "include"
  };



  React.useEffect(() => {

    getUrl(`/api/transaction?page=0&size=${size}`)
      /*  fetch(process.env.REACT_APP_TITLE + `/transaction?page=0&size=${size}`, {
         method: 'GET',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         credentials: "include"
       }) */
      .then(response => {

        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {

        setItems(data)
      },
        (error) => {
          navigate("/login")
          //setIsLoaded(true);
          //setError(error);
        }
      )

      ;
  }, [])

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [isAll, setIsALl] = useState(false);
  const [size, setSize] = useState(40);
  const [userId, setUserId] = useState("");

  const changeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'Id', // accessor is the "key" in the data
      },
      {
        Header: 'Пользователь',
        accessor: 'UserId',
      },
      {
        Header: 'Время (UTC+0)',
        accessor: 'Time',
        Cell: ((value: any) => {

          var v = value.cell.value
          var a = new Date(v).toISOString().
            replace(/T/, ' ').      // replace T with a space
            replace(/\..+/, '')

          /*  var TheDate = new Date( Date.UTC(2012, 10, 5) );
           var a = format(new Date(v), 'dd.MM.yyyy kk:mm:ss') */
          return a
        }),
      },
      {
        Header: 'Тип',
        accessor: 'Type',
      },
      {
        Header: 'Сумма до',
        accessor: 'TotalBefore',
      },
      {
        Header: 'Сумма после',
        accessor: 'TotalNow',
      },

      {
        Header: 'Оплата',
        accessor: 'YouKassa',
      },
    ],
    []
  )

  const fetchMoreData = () => {
    setTimeout(() => {
      if (userId == "") {

        getUrl(`/api/transaction?page=${page + 1}&size=${size}`)

          /*    fetch(base_url + `/transaction?page=${page + 1}&size=${size}`, {
               method: 'GET',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
               credentials: "include"
             }) */
          .then(response => {
            if (response.status == 401) {
              props.setToken(false)
              return;

            }
            return response.json()
          })
          .then(data => {



            setItems(items.concat(data));
            setPage(page + 1)
            //  setItems(data)
          }
          );
      }
      else {

        if (!isAll) {
          checkWithFilter();
        }
      }

    }, 2);
  };


  var checkWithFilter = () => {
    getUrl(`/api/transaction?page=${page}&size=${size}&userId=${userId}`)
      /*  fetch(base_url + `/transaction?page=${page + 1}&size=${size}&userId=${userId}`, {
         method: 'GET',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         credentials: "include"
       }) */
      .then(response => {
        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {


        /*     if (data.length > 0) {
              setItems(items.concat(data));
    
              setPage(page + 1)
              if (data.length < size)
                setIsALl(true)
              //  setItems(data)
            }
            else { */

        setItems(data);
        setIsALl(true)
        /*        } */
      }

      );

  }
  const data = React.useMemo(() => items, [items]);

  var userFilter = () => {
    fetch(base_url + `/transaction?page=${0}&size=${size}&userId=${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: "include"
    })
      .then(response => {

        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {


        setIsALl(false)
        setItems(data);
        setPage(data.length >= 40 ? 1 : 0)
        //  setItems(data)
      }
      );

  }

  return (
    

      <Box sx={{ pt: 2 }}>



        <Styles>

          <Paper
            component="form"
            sx={{  display: 'flex', alignItems: 'center', width: "100%", mb:2 }}
          >

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Id пользователя"
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={changeUserId}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={checkWithFilter}>
              <SearchIcon />
            </IconButton>
            {/*  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
            {/*    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                    <DirectionsIcon />
                </IconButton> */}
          </Paper>
          <Table columns={columns} data={data} update={fetchMoreData}  />
        </Styles>
      </Box>
  
  );
}

export default TransactionTable;
