import ResizableBox from "./ResizableBox";
import useDemoConfig from "./useDemoConfig";
import React, { useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Line } from 'react-chartjs-2';
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { format } from "date-fns";
import { getUrl } from "../auth/getUrl";
import { LineChart } from '@mui/x-charts/LineChart';
import { Block } from "@mui/icons-material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import { PayInfo } from "./tabs/PayInfo";
import { TmPayInfo } from "./tabs/TmPayInfo";
import { PcPayInfo } from "./tabs/PcPayInfo";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { XrayInfo } from "./tabs/XrayInfo";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};





export default function TransactionStatistic() {



    const [items, setItems] = useState<any>({
        labels: [],
        datasets: [
            {

            },

        ]
    });
    const navigate = useNavigate();
    const [newUsers, setNewUsers] = useState<any>([]);
    const [payInfo, setPayInfo] = useState<any>({});
    const [payInfoTm, setPayInfoTm] = useState<any>({});
    const [payInfoPc, setPayInfoPc] = useState<any>({});


    const [payInfoData, setPayInfoData] = useState<any>({});
    const [payInfoTmData, setPayInfoTmData] = useState<any>({});
    const [payInfoPcData, setPayInfoPcData] = useState<any>({});


    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const [data, setData] = useState<any>({
        labels: [],
        datasets: [
            {

            },

        ]
    });

    const [dataCount, setDataCount] = useState<any>({
        labels: [],
        datasets: [
            {

            },

        ]
    });
    /*  const data = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: [1,23,4,5,6],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Dataset 2',
          data: [4,34,45,45,233],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }; */


    const options1 = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Ожидание-реальность',
            },
        },

    };

    React.useEffect(() => {
        getUrl(`/api/total`)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setData({
                    labels: data.Expected.map((x: any) => getParsedDate(new Date(x.date), false)),
                    datasets: [
                        {
                            label: 'Ожидаемый заработок',
                            data: data.Expected.map((x: any) => x.sum),


                            borderColor: '#0080006b',
                            backgroundColor: '#0080006b',
                            tension: 0.4,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 15
                        },
                        {
                            label: 'Реальный заработок',
                            data: data.Reality.map((x: any) => x.sum),
                            borderColor: '#1976d2',
                            backgroundColor: '#1976d28f',
                            tension: 0.4,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 15
                        },
                        /*         {
                                    label: 'Потеряно',
                                    data: data.Missed.map((x: any) => x.sum),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgb(255, 99, 132)',
                                    tension: 0.4,
                                    pointStyle: 'circle',
                                    pointRadius: 5,
                                    pointHoverRadius: 15
                                }, */
                    ]
                })
            })



    }, [])

    const [value, setValue] = React.useState<any>("1");
    const handleChange = (newValue: any) => {
        setValue(newValue);

        var a = newValue.target.value.replace("-", '')
        getUrl(`/api/getNewUsersByDate?date=` + a)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setNewUsers(data)
            }, error => {
                navigate("/login")
            }
            );
    };
    var getParsedDate = (d: Date, showTime: boolean = true): string => {

        let date = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();

        var h = d.getHours();
        var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

        return showTime ? date + "." + month + "." + year + " " + h + ":" + m : date + "." + month + "." + year

    }

    var getAllDataCount = (): number => newUsers.length
    var getAllDataSum = (): number => newUsers.map((x: any) => x.sum).reduce((a: any, b: any) => a + b, 0)

    const [dateStart, setDateStart] = React.useState<Dayjs | null>(null);
    const [dateEnd, setDateEnd] = React.useState<Dayjs | null>(null);
    const [counter, setCounter] = React.useState<any>(null);

    /*   var setDate = () => {
  
      } */


    const [value1, setValue1] = React.useState('1');

    const handleChange1 = (event: React.SyntheticEvent, newValue: string) => {
        setDateStart(null)
        setDateEnd(null)
        setValue(newValue);
    };

    const handleChange2 = (event: SelectChangeEvent) => {
        setCounter(event.target.value as string);
      };



    const onDateApply = () => {
        setDateStart(dateStart)
        setDateEnd(dateEnd)
    };


    return (

        <div>
            <Box sx={{ mt: 2, mb: 2, p: 2, background: '#e3f2fd' }}>
                {/*     <Typography>В работе!</Typography> */}
                <Box sx={{ display: 'flex', mr: 2 }}>
                    <Box sx={{ mr: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Дата начала"
                                value={dateStart}
                                onChange={(newValue) => {
                                    setDateStart(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box  sx={{ mr: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Дата конца"
                                value={dateEnd}
                                onChange={(newValue) => {
                                     
                                    setDateEnd(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Кол-во записей</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={counter}
                                label="Кол-во записей"
                                onChange={handleChange2}
                                style={{width:'150px'}}
                            >
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={200}>200</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {/*    <DatePicker label="Basic date picker" />
                <DatePicker label="Basic date picker" /> */}
                    {/* <Button variant="contained" disabled={dateStart == null && dateEnd == null} onClick={onDateApply}>Применить</Button> */}
                </Box>
            </Box>


            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList value={value}  onChange={handleChange1} aria-label="lab API tabs example" textColor="secondary"
                        indicatorColor="secondary">
                        <Tab label="Outline" value="1" />
                        <Tab label="Xray" value="2" />
                        <Tab label="ПК" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1"> <PayInfo dateStart={dateStart?.toDate().toLocaleDateString() } dateEnd={dateEnd?.toDate().toLocaleDateString()} counter={counter}></PayInfo></TabPanel>
                <TabPanel value="2"> <XrayInfo dateStart={dateStart?.toDate().toLocaleDateString()} dateEnd={dateEnd?.toDate().toLocaleDateString()} counter={counter}></XrayInfo></TabPanel>
                <TabPanel value="3"> <PcPayInfo dateStart={dateStart?.toDate().toLocaleDateString()} dateEnd={dateEnd?.toDate().toLocaleDateString()} counter={counter}></PcPayInfo></TabPanel>
            </TabContext>

            


            {/*   <Box>
                <PayInfo></PayInfo>
            </Box> */}


            {/*    <Box>
                <TmPayInfo></TmPayInfo>
            </Box>

            <Box>
                <PcPayInfo></PcPayInfo>
            </Box> */}

            {/*  <Typography variant="h5">Текущий баланс пользователей</Typography> */}
            {/*  <Line options={options} data={items} /> */}
            {/*   <Line options={options1} data={data} />; */}
            {/*     <Line options={options1} data={dataCount} />; */}
            <Box style={{ paddingTop: 40 }}>
                {/*            <Typography variant="h5">Пополнения новых пользователей</Typography>
                <Typography variant="subtitle2" style={{ display: "flex", paddingTop: 10 }} gutterBottom>
                    Всего пополнений: {getAllDataCount()}
                </Typography>
                <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10 }} gutterBottom>
                    Общая сумма: {getAllDataSum()}
                </Typography>
                <Box style={{ display: "flex", paddingBottom: 10 }}>
                    <TextField
                        id="date"
                        label="Дата создания кошелька"
                        type="date"
                        defaultValue="current"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                    />


                </Box> */}

                {/*     <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >Номер</TableCell>
                                <TableCell align="left" >Индефикатор пользователя</TableCell>
                                <TableCell align="left">Время создания кошелька</TableCell>
                                <TableCell align="left">Время пополнения</TableCell>
                                <TableCell align="left">Сумма</TableCell>
                                <TableCell align="left">Реферальная</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newUsers.map((row: any, i: any) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{i + 1}</TableCell>
                                    <TableCell align="left">{row.user_id}</TableCell>
                                    <TableCell align="left">{getParsedDate(new Date(row.time_created))}</TableCell>
                                    <TableCell align="left">{getParsedDate(new Date(row.time_payed))}</TableCell>
                                    <TableCell align="left">{row.sum}</TableCell>

                                    <TableCell align="left">{row.ref.Valid ? row.ref.Int64 : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> */}
            </Box>


        </div>


    )
}
